<template>
  <div class="allPage">
    <heads :more="showMore" :msg="title_msg"></heads>
    <div v-if="!zkb_pro" class="tabs">
      <van-tabs

        v-model="active"
        title-inactive-color="#333333"
        title-active-color="#ee2e2e"
        @click="clickTabs"
      >
        <van-tab title="课程">
          <van-list
            v-show="isShow"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
          >
            <div v-for="(lesson, index) in kclist" :key="index" @click="go_url(lesson.url,lesson)">
              <datalists
                :type="2"
                :list-type="4"
                :thumb="lesson.thumb"
                :atitle="lesson.lesson_name"
                :price="lesson.price"
                :v-num="lesson.buy_count"
                :c-num="lesson.comment_rate"
              ></datalists>
              <!-- 学员数 暂时用   原代码用的status 不对，这个是状态 -->
            </div>
          </van-list>
        </van-tab>
        <van-tab title="帖子">
          <van-list
            v-show="isShow1"
            v-model="loading1"
            :finished="finished1"
            finished-text="没有更多了"
            @load="onLoad1"
          >
            <div v-for="(thread,idx) in tzlist" :key="idx" @click="go_url(thread.detail_url)">
              <datalists
                :type="1"
                :list-type="1"
                :thumb="thread.pic"
                :atitle="thread.title"
                :username="thread.username"
                :create-time="thread.create_time"
                :v-num="thread.view_num"
                :c-num="thread.reply_num"
              ></datalists>
            </div>
          </van-list>
          <div v-show="!isShow1" class="kongzt">
            <img src="../../static/images/my_index/kongzt.png" alt>
            <p>您还没有收藏的帖子~</p>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div v-else class="tabs">
      <div>
        <van-list
          v-show="isShow"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="(lesson, index) in kclist" :key="index" @click="go_url(lesson.url,lesson)">
            <datalists
              :type="2"
              :list-type="4"
              :thumb="lesson.thumb"
              :atitle="lesson.lesson_name"
              :price="lesson.price"
              :v-num="lesson.buy_count"
              :c-num="lesson.comment_rate"
            ></datalists>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import commontop from '@/compontens/menu.vue'
import tool from '@/utils/tools'
import { get_myshoucang, get_mytiezi } from '@/api/my_user'
import datalists from '@/compontens/datalist'

export default {
  components: {
    heads,
    commontop,
    datalists
  },
  data() {
    return {
      active: 0,
      title_msg: '我的收藏',
      isshow: false,
      kclist: [],
      tzlist: [],
      loading: false,
      finished: false,
      isShow: true,
      loading1: false,
      finished1: false,
      isShow1: true,
      retlen: 0,
      retlen1: 0,
      limit: 10,
      page: 1,
      limit1: 10,
      page1: 1,
      total: 0,
      total1: 0,
      showMore: true,
      // 是否是注考帮pro打开的页面：1 ：是
      zkb_pro: 0,
      category_id: this.$route.query.category_id || '',
      category_name: decodeURIComponent(this.$route.query.category_name || '')
    }
  },
  mounted() {
    const zkb_pro = this.$route.query.zkb_pro || ''
    console.log('zkb_pro = ', this.zkb_pro)
    if (zkb_pro == 1 || window.location.hostname.includes('zk')) {
      this.zkb_pro = 1
      this.showMore = false
    }
    if (this.zkb_pro == 1) {
      this.active = 0
    } else {
      const index = sessionStorage.getItem('cc_idx') ? sessionStorage.getItem('cc_idx') : 0
      this.active = Number(index)
    }
  },
  destroyed() {
    sessionStorage.removeItem('cc_idx')
  },
  beforeMount() {
    if (tool.isInApp()) {
      if (tool.isInIosApp()) {
        window.onpageshow = function(e) {
          if (e.persisted) {
            window.location.reload()
          }
        }
      }
    }
  },
  methods: {

    clickTabs(val) {
      sessionStorage.setItem('cc_idx', val)
    },
    // 点击课程列表跳转详情
    go_url(urls, lesson) {
      console.log(urls)
      if (this.zkb_pro == 1) {
        // 注考帮
        const origin = window.location.origin
        // const lesson_url = `${origin}/edu/d/${lesson.lesson_id}.html?zkb_pro=1&category_id=${this.category_id}&category_name=${this.category_name}#i=2`
        const lesson_url = `${origin}/kb_timetable?lesson_id=${lesson.lesson_id}&zkb_pro=1&category_id=${this.category_id}&category_name=${this.category_name}#i=2`
        window.location.href = lesson_url
      } else {
        var sliStr = urls.split('?')
        var url = sliStr[0] + '?wdsc=1&' + (sliStr[1] || '')
        window.location.href = url
      }
    },
    formatDate(time) {
      if (time !== null && time !== '') {
        time = time * 1000
        const date = new Date(time)
        return tool.formatDate(date, 'yyyy-MM-dd')
      } else {
        return ''
      }
    },
    onLoad() {
      var params = {
        page: this.page,
        limit: this.limit,
        zkb_pro: this.zkb_pro
      }
      get_myshoucang(params).then((res) => {
        console.log(res)
        // this.kclist = res.result.list
        if (!res.result.list.length && !this.kclist.length) {
          // if (true) {
          this.isShow = false
          return false
        }
        this.kclist = this.kclist.concat(res.result.list)
        this.total = res.result.count // 数据总条数
        this.retlen = res.result.list.length // 本次返回数据的长度
        if (this.retlen < this.limit) {
          this.finished = true // 数据加载完毕显示 没有更多了
          this.isShow = true
        }
        this.page++
        this.loading = false
        // console.log(this.list)
      })
    },
    onLoad1() {
      var params = {
        page: this.page1,
        limit: this.limit1
      }
      get_mytiezi(params).then((res) => {
        console.log(res)
        if (!res.result.list.length && !this.tzlist.length) {
          // if (true) {
          this.isShow1 = false
          return false
        }
        this.tzlist = this.tzlist.concat(res.result.list)
        this.total1 = res.result.count // 数据总条数
        this.retlen1 = res.result.list.length // 本次返回数据的长度
        if (this.retlen1 < this.limit1) {
          this.finished1 = true // 数据加载完毕显示 没有更多了
          this.isShow1 = true
        }
        this.page1++
        this.loading1 = false
      })
    },
    clicks() {
      if (this.isshow === false) {
        this.isshow = true
      } else {
        this.isshow = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// 课程样式
.lessons {
  // width: 100%;
  // background-color: #fff;
  // display: inline-block;
  // border-bottom: 1px solid #e1e1e1;
  .lists {
    margin: 30px 30px 30px 30px;
    width: calc(100% - 60px);
    display: flex;
    flex-direction: row;
    .thumb {
      margin-top: 10px;
      img {
        margin-right: 20px;
        width: 258px;
        height: 145px;
      }
    }
    .right {
      .lessonName {
        min-height: 80px;
        font-size: 28px;
        color: #333;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .midInfo {
        margin-top: 5px;
        color: #999;
        font-size: 24px;
        display: flex;
        align-items: center;
        .student {
          width: 32px;
          height: 26px;
        }
        .stuNum {
          display: inline-block;
          margin-left: 7px;
          color: #999;
          font-size: 24px;
        }
        .rate {
          margin-left: 30px;
          width: 29px;
          height: 29px;
        }
        .rateNum {
          display: inline-block;
          margin-left: 10px;
          color: #999;
          font-size: 24px;
        }
      }
      .botInfo {
        margin-top: 10px;
        span {
          color: #ee2e2e;
          font-size: 30px;
        }
      }
    }
  }
}

.kongzt {
  width: 100%;
  img {
    width: 310px;
    height: 310px;
    margin: 0 auto;
    display: block;
    margin-top: 300px;
  }
  p {
    font-size: 30px;
    font-weight: 600;
    color: #999;
    text-align: center;
    padding-top: 30px;
  }
}

/deep/.van-tabs__nav--line {
  padding-bottom: 0px;
}
/deep/.van-tabs__wrap {
  border-bottom: 1px solid #eee;
}
/deep/.van-tabs__line {
  height: 4px;
  bottom: 0px;
  border-radius: 0;
}
.allPage {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #f5f5f5;
}
</style>
